@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('../fonts/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.apple-pay-button {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  appearance: none;
  MozAppearance: none;
  WebkitAppearance: none;
  border: none;
  padding: 5px;
  border-radius: 3px;
  background-color: black;
}


.apple-pay-button:focus {
  outline: 0;
}

.apple-pay-button-light {
  background-color: white;
}

.apple-pay-button-light:hover {
  background-color: #EEEEEE;
}

.apple-pay-button-dark {
  background-color: black;
}

.apple-pay-button-dark:hover {
  background-color: #111111;
}
